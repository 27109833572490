import React from "react"
import { Link } from "gatsby"
import Layout from "../components/layout"
import Cta from "../components/cta"
import Seo from "../components/seo"
import scrollTo from 'gatsby-plugin-smoothscroll'
import TestimonialSection from "../components/testimonials-section"
import ArrowRight from "../components/svg/arrowRight"

const MlPage = () => (
    <Layout>
        <Seo 
            title="Machine Learning Developers Sydney Canberra, Australia" 
            description="Launch Lab offer Machine Learning development in Sydney and Canberra. We use Python, Tensorflow, ML and data science to improve businesses and startups."
            pathname="/machine-learning-developers-sydney/"
            serviceType="Machine learning development"
        /> 

        <section role="main" className="bg-purple with-pad-sm white">
          <div className="container">
              <div className="row flex flex-jc">
                  <div className="hero-content sub-hero">
                      <h1
                      className="small-h"
                      data-sal="slide-up" 
                      data-sal-easing="ease"
                      data-sal-delay="100"
                      data-sal-duration="400"
                      >Machine Learning Developers</h1>
                      <h2 className="hero-lead"
                      data-sal="slide-up" 
                      data-sal-easing="ease"
                      data-sal-delay="300"
                      data-sal-duration="400"
                      >
                          We use Python, PyTorch, Tensorflow, ML and data science to solve business problems
                      </h2>
                      <div className="btn-row">
                      <button onClick={() => scrollTo('#anchor')} className="btn btn-lg btn-pink">
                          <span className="flex flex-ac down">
                          <ArrowRight />
                          </span>
                      </button>
                      </div>
                  </div>
                </div>
            </div>
        </section>

        <section className="section-pad" id="anchor">
            <div className="container">
                <div className="row">
                    <div className="cols">
                        <div className="col-26">
                            <h3>Data Science Sydney &amp; Canberra</h3>
                        </div>

                        <div className="col-67 long-text">
                            <p>
                                Launch Lab develops <strong>ML models and applications</strong> using PyTorch and Google's Tensorflow.
                            </p>
                            <p>
                                If you're a data rich organisation we'd love to discuss how machine learning and <a href="/artificial-intelligence-developers/">artificial intelligence</a> can deliver insights and results for your business.
                            </p>
                            <p>
                                If you're a startup (post revenue with validation) and want to be AI-ready talk to us.
                            </p>
                            <div className="btn-row">
                                <Link to="/contact/" className="btn btn-lg btn-purple">
                                    <span className="flex flex-ac">
                                        Contact us
                                    <ArrowRight />
                                    </span>
                                </Link>
                            </div>
                            <div className="mt-4">
                                <h4>AI bots</h4>
                                <p>
                                    In regards to developing <Link to="/chatbot-developers/">chatbots and voice applications</Link> we leverage Google's AI (Dialogflow) and AWS' AI.
                                </p>
                                <p>
                                    Whether you want to deliver a better user experience by adding a bot to your website, or social channels, or open a new distribution channel and consumer touch point with a Google Action or Alexa Skill (voice app) contact us.
                                </p>
                                <h4>ML case study</h4>
                                <p>
                                    In a recent project for an Australian biotech company, we built a machine learning model for ranking molecular structures for drug candidate viability.
                                </p>
                                <p>
                                    Building on our 16 years of experience with machine learning with <Link to="/python-developer/">Python</Link>, we used TensorFlow along with the state-of-the-art TF-Ranking library, based on academic research published in the last 12 months.
                                </p>
                                <p>
                                    The neural network was trained on an extensive dataset provided by our client. The model has now been put to work, successfully ranking drug candidates from molecular structures unlike any in the training dataset.
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>

        <TestimonialSection />

        <Cta 
            text="We'd love to discuss how we can assist with your next ML, AI or data science project."
        />
    </Layout>
)

export default MlPage